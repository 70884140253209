import { lazy, Suspense, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useLocation } from "react-router-dom";
const NavBar = lazy(() => import("./Components/Layout/Navbar"));
const Footer = lazy(() => import("./Components/Layout/Footer"));
const Loader = lazy(() => import("./Components/Layout/Loader"));
const Home = lazy(() => import("./Components/Home"));
const Team = lazy(() => import("./Components/Team"));

function App() {
  function ScrollToHash() {
    const { hash } = useLocation();

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [hash]);

    return null;
  }
  return (
    <>
      <ScrollToHash />
      <Suspense fallback={<Loader />}>
        <NavBar />
        <div>
          <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`/team`} element={<Team />} />
          </Routes>
        </div>
        <Footer/>
      </Suspense>
    </>
  );
}

export default App;
